var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('Navbar'),(
      _vm.$router.currentRoute.name !== 'ElektraWeb' &&
      !_vm.$router.currentRoute.path.includes('Eservices')
    )?_c('Showcase'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1),(
      _vm.$router.currentRoute.name !== 'ElektraWeb' &&
      !_vm.$router.currentRoute.path.includes('Eservices')
    )?_c('Partners'):_vm._e(),(
      _vm.$router.currentRoute.name !== 'ElektraWeb' &&
      !_vm.$router.currentRoute.path.includes('Eservices')
    )?_c('Team'):_vm._e(),_c('Form'),_c('Footerend')],1)}
var staticRenderFns = []

export { render, staticRenderFns }