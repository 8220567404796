<template>
  <div class="bg">
    <div class="container">
      <div class="row" style="padding-bottom: 20px; text-align: left">
        <div class="pl-3">
          <div style="margin-bottom: 20px">
            <span style="font-weight: bold; color: white; font-size: 20px">
              <img src="@/assets/mainLogo.svg" style="width: 130px" alt=""
            /></span>
          </div>
        </div>
      </div>
      <div class="row">
        <p
          class="pl-3"
          style="color: grey; text-align: left; font-size: 16px !important"
        >
          © {{ new Date().getFullYear() }} Digisense. All right reserved
        </p>
      </div>
      <a style="color: grey; font-size: 11px" href="http://www.freepik.com">
        Showcase image from: Designed by starline / Freepik</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Footerend",
  data() {
    return {};
  },
  async mounted() {},

  methods: {},

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
.bg {
  background-image: url("../assets/technobg.webp");
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 100px;
}
.navItem {
  color: #f7f8fc;
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
}
.navItem:hover {
  color: #f5ad0b;
}
.ddItem {
  text-align: right;
}
</style>
