<template>
  <div
    class="container-fluid"
    style="margin-bottom: 100px; padding-top: 90px"
    id="2"
  >
    <div class="">
      <h2 style="font-weight: 700; margin-bottom: 100px; text-align: center">
        {{ $t("partnersHeader") }}
      </h2>
    </div>
    <div class="row" style="text-align: center">
      <div class="col-xl col-md col-sm-12 pad">
        <a href="https://www.eptera.com/en/" target="_blank"
          ><img
            class=""
            src="@/assets/partners/eptera.svg"
            style="width: 200px; margin-top: -5px"
            alt=""
        /></a>
      </div>
      <div class="col-xl col-md col-sm-12 pad">
        <a href="http://efectura.com/" target="_blank"
          ><img
            class=""
            src="@/assets/partners/efectura.webp"
            style="width: 200px"
            alt=""
        /></a>
      </div>
      <div class="col-xl col-md col-sm-12 pad">
        <a href="http://www.voidaint.com/" target="_blank"
          ><img
            class=""
            src="@/assets/partners/voida.webp"
            style="width: 200px; margin-top: -30px"
            alt=""
        /></a>
      </div>
      <div class="col-xl col-md col-sm-12 pad">
        <a href="http://www.barantech.com.tr/" target="_blank"
          ><img
            class=""
            src="@/assets/partners/Barantech.webp"
            style="width: 200px"
            alt=""
        /></a>
      </div>
      <div class="col-xl col-md col-sm-12 pad">
        <a href="https://www.vedubox.com/en/" target="_blank"
          ><img
            class=""
            src="@/assets/partners/vedubox.webp"
            style="width: 200px; margin-top: 5px"
            alt=""
        /></a>
      </div>
      <div class="col-xl col-md col-sm-12 pad">
        <a href="https://www.mobilgi.com/" target="_blank"
          ><img
            class=""
            src="@/assets/partners/mdigi.webp"
            style="width: 200px; margin-top: 15px"
            alt=""
        /></a>
      </div>
    </div>
    <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
      <img
        class="my-img"
        src="https://user-images.githubusercontent.com/11708491/56092557-29952600-5edb-11e9-8cde-fed4513ca737.png"
      />
      <img
        class="my-img"
        src="https://user-images.githubusercontent.com/11708491/56092557-29952600-5edb-11e9-8cde-fed4513ca737.png"
      />
      <img
        class="my-img"
        src="https://user-images.githubusercontent.com/11708491/56092557-29952600-5edb-11e9-8cde-fed4513ca737.png"
      />
    </b-carousel-slide>
  </div>
</template>

<script>
export default {
  name: "Partners",
  data() {
    return {};
  },
  async mounted() {},

  methods: {},

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
.pad {
  /* padding: 15px;  */
  /* height: 200px; */
  line-height: 120px;
  /* font-weight: 550; */
}
</style>
