<template>
  <div class="container" style="padding-top: 70px; padding-bottom: 70px" id="4">
    <div>
      <h2 style="font-weight: 700; margin-bottom: 85px; text-align: center">
        {{ $t("contactHeader") }}
      </h2>
    </div>
    <form
      action="https://formsubmit.co/e6c4489d3951ef1b415ddda25c38865c"
      method="POST"
    >
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div>
            <p style="font-weight: 550; margin-top: 15px">{{ $t("name") }}</p>
            <b-form-input
              v-model="name"
              name="name"
              style="border: none; box-shadow: 0px 10px 20px 2px #bbb8b8"
              placeholder="Enter your name"
            ></b-form-input>
          </div>
          <div>
            <p style="font-weight: 550; margin-top: 15px">{{ $t("email") }}</p>
            <b-form-input
              :state="emailValid"
              name="email"
              type="email"
              v-model="email"
              style="border: none; box-shadow: 0px 10px 20px 2px #bbb8b8"
              placeholder="Enter your e-mail"
            ></b-form-input>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <p style="font-weight: 550; margin-top: 15px">{{ $t("desc") }}</p>
          <b-form-textarea
            name="text"
            style="border: none; box-shadow: 0px 10px 20px 2px #bbb8b8"
            id="textarea"
            v-model="text"
            placeholder="Please describe your request in detail"
            rows="5"
            max-rows="8"
          ></b-form-textarea>
        </div>
      </div>
      <input
        v-if="emailValid && name.length > 0 && text.length > 0"
        style="
          background: #002046;
          font-weight: 600;
          border: none;
          font-size: 15px;
          margin-top: 15px;
          padding: 8px 20px 8px 20px;
          color: white;
          letter-spacing: 1.5px;
        "
        type="submit"
        value="SEND"
      />
      <b-button
        v-else
        disabled
        style="
          background: #002046;
          font-weight: 600;
          border: none;
          font-size: 15px;
          margin-top: 15px;
          padding: 8px 20px 8px 20px;
          border-radius: 0px;
          letter-spacing: 1.5px;
        "
      >
        {{ $t("send") }}
      </b-button>
    </form>
    <div class="row mt-5">
      <div class="col-sm-12 col-md-6">
        <h5 style="font-weight: 700">
          {{ $t("contactHeader2") }}
        </h5>
        <img
          src="@/assets/manager.cf345634.png"
          alt="eka"
          style="width: 100px; border-radius: 12px"
          class="mt-2"
        />
        <p class="mt-3" style="font-weight: 700">
          eka.natsvlishvili@digisense.ge <br />
          +995 577 45 80 92
        </p>
      </div>
      <div class="col-sm-12 col-md-6">

      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "Form",
  data() {
    return {
      email: "",
      name: "",
      text: "",
      show: true,
    };
  },
  methods: {},
  computed: {
    emailValid() {
      return this.email.includes("@") ? true : false;
    },
  },
};
</script>

<style scoped></style>
