<template>
  <div>
    <b-jumbotron class="jtron" style="text-align: left; color: white" fluid>
      <div class="row">
        <div class="col-xl-5 col-md-6 col-sm-12">
          <h2 class="mb-5" style="font-weight: 700">{{ $t("opening") }}</h2>
          <p style="font-weight: 400">{{ $t("expl") }}</p>
        </div>
        <!-- <div class="col-xl-7 col-md-6 col-sm-12" style="">
          <vue-particles
            color="#05affc"
            :particleOpacity="0.5"
            :particlesNumber="130"
            shapeType="circle"
            :particleSize="5"
            linesColor="#013bb4"
            :linesWidth="3"
            :lineLinked="true"
            :lineOpacity="0.4"
            :linesDistance="150"
            :moveSpeed="5"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
          >
          </vue-particles>
        </div> -->
      </div>
      <!-- <b-button style="" variant="danger">Learn more</b-button> -->
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: "Showcase",
  data() {
    return {};
  },
  async mounted() {},

  methods: {},

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
.jtron {
  background-image: url("../assets/technobg.webp");
  background-size: cover;
  min-height: 100vh;
  padding-top: 30vh;
  justify-content: center;
}
</style>
