<template>
  <div class="container" style="padding-top: 90px; padding-bottom: 70px" id="1">
    <div class="container">
      <div>
        <h2 style="font-weight: 700; margin-bottom: 20px; text-align: center">
          {{ $t("mainHeader") }}
        </h2>
        <p style="text-align: center; font-weight: 500">{{ $t("mainPar") }}</p>
      </div>
      <div class="row" style="text-align: center">
        <div class="col-xl-3 col-md-3 col-sm-12 mt-2" style="padding-top: 25px">
          <div class="pad">
            <img
              src="@/assets/service icons/hot.webp"
              style="width: 60px; margin-bottom: 20px; margin-top: 10px"
              alt=""
            />
            <h4 style="font-weight: 500; margin-bottom: 30px">
              {{ $t("hm") }}
            </h4>
            <a
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              target="_blank"
              href="https://www.eptera.com/en/"
            >
              <p class="un">{{ $t("m1") }}</p>
            </a>

            <br>

            <router-link
                class=""
                style="text-decoration: none; color: #002046"
                id=""
                :to="{ name: 'Roomsware', params: {} }"
            >
              <p class="un">Roomsware</p>
            </router-link>
          </div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-12 mt-2" style="padding-top: 25px">
          <div class="pad">
            <img
              src="@/assets/service icons/mob.webp"
              style="width: 60px; margin-bottom: 20px; margin-top: 10px"
              alt=""
            />
            <h4 style="font-weight: 500; margin-bottom: 30px">
              {{ $t("dig") }}
            </h4>
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'OneStore', params: {} }"
            >
              <p class="un">{{ $t("m2") }}</p>
            </router-link>
            <br />
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'Fmcg', params: {} }"
            >
              <p class="un">{{ $t("m3") }}</p>
            </router-link>
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'Fam', params: {} }"
            >
              <p class="un">{{ $t("m4") }}</p>
            </router-link>
          </div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-12 mt-2" style="padding-top: 25px">
          <div class="pad">
            <img
              src="@/assets/service icons/camp.webp"
              style="width: 60px; margin-bottom: 20px; margin-top: 10px"
              alt=""
            />
            <h4 style="font-weight: 500; margin-bottom: 30px">
              {{ $t("cm") }}
            </h4>
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'Cortex', params: {} }"
            >
              <p class="un">{{ $t("m5") }}</p>
            </router-link>
          </div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-12 mt-2" style="padding-top: 25px">
          <div class="pad">
            <img
              src="@/assets/service icons/adv.webp"
              style="width: 60px; margin-bottom: 20px; margin-top: 10px"
              alt=""
            />
            <h4 style="font-weight: 500; margin-bottom: 30px">
              {{ $t("adv") }}
            </h4>
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'Scp', params: {} }"
            >
              <p class="un">{{ $t("m6") }}</p>
            </router-link>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12 mt-2" style="padding-top: 25px">
          <div class="pad" style="">
            <img
              src="@/assets/service icons/tv.webp"
              style="width: 60px; margin-bottom: 20px; margin-top: 10px"
              alt=""
            />
            <h4 style="font-weight: 500; margin-bottom: 30px">
              {{ $t("ts") }}
            </h4>
            <div class="row">
              <div class="col">
                <router-link
                  class=""
                  style="text-decoration: none; color: #002046"
                  id=""
                  :to="{ name: 'Strings', params: {} }"
                >
                  <p class="un">{{ $t("m7") }}</p>
                </router-link>
                <br />
                <router-link
                  class=""
                  style="text-decoration: none; color: #002046"
                  id=""
                  :to="{ name: 'Ota', params: {} }"
                >
                  <p class="un">{{ $t("m8") }}</p>
                </router-link>
                <br />
                <router-link
                  class=""
                  style="text-decoration: none; color: #002046"
                  id=""
                  :to="{ name: 'Psg', params: {} }"
                >
                  <p class="un">{{ $t("m9") }}</p>
                </router-link>
              </div>
              <div class="col">
                <router-link
                  class=""
                  style="text-decoration: none; color: #002046"
                  id=""
                  :to="{ name: 'Scout', params: {} }"
                >
                  <p class="un">{{ $t("m10") }}</p>
                </router-link>
                <br />
                <router-link
                  class=""
                  style="text-decoration: none; color: #002046"
                  id=""
                  :to="{ name: 'FreeCooling', params: {} }"
                >
                  <p class="un">{{ $t("m11") }}</p>
                </router-link>
                <br />
                <router-link
                  class=""
                  style="text-decoration: none; color: #002046"
                  id=""
                  :to="{ name: 'Bbs', params: {} }"
                >
                  <p class="un">{{ $t("m12") }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-12 mt-2" style="padding-top: 25px">
          <div class="pad">
            <img
              src="@/assets/service icons/wall.webp"
              style="width: 60px; margin-bottom: 20px; margin-top: 10px"
              alt=""
            />
            <h4 style="font-weight: 500; margin-bottom: 30px">
              {{ $t("fs") }}
            </h4>
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'MicroCredit', params: {} }"
            >
              <p class="un">{{ $t("m13") }}</p>
            </router-link>
            <br />
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'Fmw', params: {} }"
            >
              <p class="un">{{ $t("m14") }}</p>
            </router-link>
            <br />
          </div>
        </div>
        <div class="col-xl-3 col-md-3 col-sm-12 mt-2" style="padding-top: 25px">
          <div class="pad">
            <img
              src="@/assets/service icons/learn.webp"
              style="width: 60px; margin-bottom: 20px; margin-top: 10px"
              alt=""
            />
            <h4 style="font-weight: 500; margin-bottom: 30px">
              {{ $t("el") }}
            </h4>
            <router-link
              class=""
              style="text-decoration: none; color: #002046"
              id=""
              :to="{ name: 'Vedubox', params: {} }"
            >
              <p class="un">{{ $t("m15") }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {};
  },
  async mounted() {
    document.title = "Digisense";
  },

  methods: {},

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
.pad {
  background: white;
  padding: 15px;
  height: 350px;
  box-shadow: 0px 10px 20px 2px #bbb8b8;
  font-weight: 550;
}

.check {
  color: #002046;
}

.check:hover {
  color: white;
}

.un {
  display: inline-block;
  padding-bottom: 2px;
  background-image: linear-gradient(#002046, #002046);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  font-weight: 400;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
}

.un:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 2px;
}
</style>
